import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../components/Layouts/Layout';
import { Header } from '../components/Blocks/Header';
import { TwoColumns } from '../components/Layouts/TwoColumns';
import SEO from '../components/Layouts/SEO';
import privacyIcon from '../images/icons/quality-privacy-icon.svg';
import ecoIcon from '../images/icons/quality-eco-icon.svg';
import pageStyles from './page.module.scss';
import styles from './quality.module.scss';

const imageComponent = (item) => (
  <GatsbyImage alt={item.title} image={item.image.childImageSharp.gatsbyImageData} />
);

export function Head() {
  return (
    <>
      <SEO title="Ответственный подход к качеству" description="Наш подход к качеству" />{' '}
      <YandexTableau />
    </>
  );
}

const messageComponent = (item) => (
  <div className={styles.message}>
    <h2 className={styles.title}>{item.title}</h2>
    <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />
  </div>
);

function QualityPage({
  data: {
    allQualityJson: { nodes: items },
  },
}) {
  return (
    <Layout>
      <div
        className={cn({
          [pageStyles.header]: true,
          [styles.title]: true,
        })}
      >
        <Header>Ответственный подход к качеству</Header>
      </div>
      <div className={pageStyles.content}>
        {items.map((item, index) => (
          <TwoColumns
            key={item.key}
            col1ClassName={index % 2 !== 0 ? styles.col1 : styles.col2}
            col2ClassName={index % 2 !== 0 ? styles.col2 : styles.col1}
            col1={
              index % 2 !== 0
                ? imageComponent(item, `${item.key}-${index}-left`)
                : messageComponent(item, `${item.key}-${index}-left`)
            }
            col2={
              index % 2 === 0
                ? imageComponent(item, `${item.key}-${index}-right`)
                : messageComponent(item, `${item.key}-${index}-right`)
            }
          />
        ))}
        <div className={styles.grayBlock}>
          <h2 className={styles.header}>Ответственность</h2>
          <TwoColumns
            col1={
              <div className={styles.column}>
                <img className={styles.icon} src={privacyIcon} />
                <p className={styles.text}>
                  Для нас важна приватность ваших данных, поэтому мы автоматизировали все процессы.
                  Ваши фотографии и личные данные под защитой.
                </p>
              </div>
            }
            col2={
              <div className={styles.column}>
                <img className={styles.icon} src={ecoIcon} />
                <p className={styles.text}>
                  Мы бережно относимся к экологиии, поэтому все используемые нами материалы могут
                  быть переработаны.
                </p>
              </div>
            }
          />
        </div>
      </div>
    </Layout>
  );
}

export default QualityPage;

export const query = graphql`
  {
    allQualityJson {
      nodes {
        key
        image {
          publicURL
          relativePath
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        title
        description
      }
    }
  }
`;
